import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { toast } from "react-toastify";
import { uploadImageAction, validateUploadReceiptAction } from "../../redux/actions";
import PreviousReceiptList from "./PreviousReceiptList";
import FileDropZone from "../FileDropZone";
import { useSelector } from "react-redux";
import Captcha from "../CaptchaClick";
import { generatePdfFromImages } from "../../hooks/ImagesToPDF";
const UploadReceipt = (props) => {
	const { t } = useTranslation();
	const config = useSelector((state) => state.app.config);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	let [multiReceiptUpload, setMultiReceiptUpload] = useState(false);
	let [selectedFile, setSelectedFile] = useState([]);
	const [receiptLoadCounter, setReceiptLoadCounter] = useState(1);
	let [enableUploading, setEnableUploading] = useState(true);
	const [refreshReCaptcha, setRefreshReCaptcha] = useState({state:null,token:null,requestBody:null});
	React.useEffect(() => {
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(false);
		}
		if (config.captchaConfig) {
			if (config.captchaConfig.uploadReceiptCaptcha) {
				setCaptchaEnable(true);
			}
			if (config.multiReceiptUpload) {
				setMultiReceiptUpload(config.multiReceiptUpload);
			}
		}
		//eslint-disable-next-line
	}, [config]);
	React.useEffect(() => {
		if(captchaEnable && refreshReCaptcha.state ==='v3' && refreshReCaptcha.token){
			let requestBody = refreshReCaptcha.requestBody;
			requestBody["captchaVal"] = refreshReCaptcha.token;
			apiHandler(requestBody);
		}
		
	}, [refreshReCaptcha]);
	const checkUploadEnableOrNot = (uploadApiCall) => {
		props.setLoading(true);
		validateUploadReceiptAction({})
			.then((responseData) => {
				props.setLoading(false);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				if (responseData["httpCode"] >= 200 && responseData["httpCode"] <= 299) {
					setEnableUploading(responseData.data.valid);
					if (uploadApiCall) {
						uploadFileHandler(responseData.data.token);
					}
					return true;
				} else {
					toast.error(`${t("Error")}! ${t("You have reached the program limit for rewards")}.`);
				}
			})
			.catch((err) => {
				console.log("error", err);
				toast.error(`${t("Error")}! ${t("You have reached the program limit for rewards")}.`);
				props.setLoading(false);
			});
	};
	const uploadImage = () => {
		
		if (selectedFile.length === 0) {
			toast.error(`${t("Error")}! ${t("Select a receipt")}.`);
			return false;
		}		
		if (!captchaValue && captchaEnable && config.captchaConfig.recaptchaVersion==='V3') { 
			setRefreshReCaptcha({...refreshReCaptcha,state:Date.now()});
			return false;
		}
		if (!captchaValue && captchaEnable) {
			toast.error(`${t("Error")}! ${t("Please select captcha")}.`);
			return false;
		}
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(true);
		} else {
			uploadFileHandler("");
		}
	};
	const uploadFileHandler = async (token) => {
		let fileData = selectedFile[0];
		if (selectedFile.length > 1) {
			fileData = await generatePdfFromImages(selectedFile);
		}
		const formData = new FormData();
		formData.append("type", "receipt");
		formData.append("image", fileData);
		if (token) {
			formData.append("token", token);
		}
		if (!captchaValue && captchaEnable && config.captchaConfig.recaptchaVersion==='V3') { 
			setRefreshReCaptcha({...refreshReCaptcha,state:Date.now()});
			return false;
		}
		if (captchaEnable) {
			formData.append("captchaVal", captchaValue);
		}
		props.setLoading(true);
		if (captchaEnable && config.captchaConfig && config.captchaConfig.recaptchaVersion==='V3') {
			setRefreshReCaptcha({...refreshReCaptcha,state:'init',requestBody:formData});
			return;
		}
		apiHandler(formData);
		
	};
	const apiHandler = (formData) => {
		uploadImageAction(formData)
			.then((responseData) => {
				props.setLoading(false);
				window.scroll({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
				setResetCaptchaValue(resetCaptchaValue + 1);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				if (responseData["httpCode"] === 200) {
					toast.success(`${t("Your receipt has been uploaded successfully. We will get back to you within 24-48 hours once we verify it")}.`);
					setSelectedFile([]);
					return true;
				} else if (responseData["httpCode"] === 409) {
					toast.error(`${t("Error")}! This receipt has already been uploaded. Please upload another receipt.`);
					setSelectedFile([]);

					return true;
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}.`);
					return false;
				}
			})
			.catch((err) => {
				//setResetCaptchaValue(resetCaptchaValue + 1);
				console.log("error", err);
				props.setLoading(false);
				if (typeof err === "object") {
					if (err["response"]) {
						if (err["response"].httpCode === 409) {
							//duplicate Receipt message
							toast.error(`${t("Error")}! This receipt has already been uploaded. Please upload another receipt.`);
							setSelectedFile([]);
							return false;
						} else {
							toast.error(`${t("Error")}! ${t("Something went wrong")}.`);
							return false;
						}
					} else {
						toast.error(`${t("Error")}! ${t("Something went wrong")}.`);
						return false;
					}
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}.`);
					return false;
				}
			});
	}
	const resetAllSelectionBtnClick = () => {
		setSelectedFile([]);
		setResetCaptchaValue(resetCaptchaValue + 1);
	};
	return (
		<Container>
			<div className="post-login">
				
					<Row xs={1} md={1} lg={1} className="text-center justify-content-center">
						<Col md={8}>
							<h2>
								<Trans>Upload your receipt</Trans>
							</h2>
						</Col>
						<Col md="12">
							<p>
								<strong><Trans>Take a clear picture of your entire receipt. The receipt must include the name and date of purchase on the photo.</Trans></strong>
							</p>
							<p>
								<Trans>Click on the box below to upload your receipt. One image will be accepted in JPG, PNG, or PDF format per entry.</Trans>
							</p>
							<p>
								<Trans>Note</Trans>: <Trans>Size of the receipt must be less than</Trans> 5 MB.
							</p>
						</Col>
					</Row>
					<Row className="justify-content-center my-4">
						<Col md="7 px-md-3">
							<PreviousReceiptList reloadCounter={receiptLoadCounter} />
						</Col>
					</Row>
					<div className="mb-3">
						<FileDropZone multiReceiptUpload={multiReceiptUpload} enableUploading={enableUploading} parentCallBack={setSelectedFile} selectedFile={selectedFile} />
					</div>
					{captchaEnable ? (
						<Row md={1}>
							<Col>
								<label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
									Google captcha
								</label>
								{
									config.captchaConfig && config.captchaConfig.recaptchaVersion==='V3'?(
									<Captcha reset={refreshReCaptcha.state} parentCallback={setCaptchaValue} 
									v3Callback={setRefreshReCaptcha}/>
									):(
										<Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue}/>
									)
								}
							</Col>
						</Row>
					) : (
						""
					)}
					{enableUploading ? (
						<Row className="justify-content-center">
							<div className="col-auto my-4">
								<div className="d-inline col"> 
									<Button size="lg" type="button" onClick={uploadImage} className="btn btn-primary" id="upload-btn">
										<Trans>Submit</Trans>
									</Button>
								</div>
								<div className="d-inline col">
									<Button size="lg" type="button" variant="light" className="px-5" onClick={resetAllSelectionBtnClick} id="upload-btn">
										<Trans>Reset</Trans>
									</Button>
							</div>
							</div>
						</Row>
					) : (
						""
					)}
				
			</div>
		</Container>
	);
};

export default IsLoadingHOC(UploadReceipt, "Loading....");
