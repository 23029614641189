import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { getUserDetails, updateUserDetails } from "../../redux/actions";
import { connect } from "react-redux";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GooglePlaces from "../../components/GooglePlaces";
import InputMask from "react-input-mask";
import Required from "../../components/Required";
var validator = require("validator");
const Profile = ({ getUserDetails, updateUserDetails, setLoading }) => {
	const { t } = useTranslation();
	const confige = useSelector((state) => state.app.config);
	const user = useSelector((state) => state.user);
	const history = useHistory();
	const [place, setPlace] = useState({});
	/*[START:INITIALIZE_DECLARATION]*/
	const [data, setData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		username: "",
		birth: "",
		homePhone: "",
		cellPhone: "",
		address: "",
		address2: "",
		country: "",
		state: "",
		suite: "",
		city: "",
		zip: "",
		shippingInstructions: "",
		gender: "",
		receiveEmail: "",
		receiveSms: "",
		companyName: "",
		instagramHandle: "",
		facebookHandle: "",
		tiktokHandle: "",
		twitterHandle: "",
		socialSecurityNumber: "",
	});
	/*[END:INITIALIZE_DECLARATION]*/
	const [validation, setValidation] = useState({});
	const [error, setError] = useState({ firstName: "", email: "", homePhone: "", cellPhone: "", lastName: "", username: "", address: "", address2: "", country: "", state: "", suite: "", zip: "", city: "", shippingInstructions: "", receiveEmail: "", receiveSms: "", gender: "", instagramHandle: "", facebookHandle: "", tiktokHandle: "", twitterHandle: "", socialSecurityNumber: "" });
	//const [calender, setCalender] = useState(false);

	useEffect(() => {
		setLoading(true);
		getUserDetails(user.id)
			.then((response) => {
				console.log("user details response", response);
				setLoading(false);
				setData({ ...data, ...response.data });
			})
			.catch((error) => {
				setLoading(false);
				console.log("error", error);
			});
		let items = {};
		if (confige.uiConfig) {
			confige.uiConfig.userFields.forEach((element) => {
				items[element.name] = element.validation;
			});
		}
		setValidation(items);
		// eslint-disable-next-line
	}, []);
	const handleChange = (e) => {
		const { name, value } = e.target;
		let errorMessage = "";
		let validationArray = validation[name];
		let check = false;
		for (let key in validationArray) {
			if (key === "minLength" && value.length !== 0) {
				if (value.length < validationArray[key]) {
					errorMessage = `${t("Should not be less then", { count: validationArray[key] })}.`;
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "maxLength") {
				if (value.length > validationArray[key]) {
					errorMessage = `${t("Should not be greater then", { count: validationArray[key] })}.`;
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "mandatory" && validationArray[key]) {
				if (!value.length) {
					errorMessage = `${t("Should not be empty")}.`;
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "email" && validationArray[key]) {
				if (!validator.isEmail(value)) {
					errorMessage = `${t("Please enter a valid email")}.`;
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "upperCaseReq" && validationArray[key]) {
				if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
					errorMessage = `${t("Should have atleast one Upper Case latter")}.`;
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "lowerCaseReq" && validationArray[key] && value.length !== 0) {
				if (value.search(/[a-z]/) < 0) {
					errorMessage = `${t("Should have atleast one Lower Case latter")}.`;
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
			if (key === "specialCharReq" && validationArray[key]) {
				// eslint-disable-next-line
				var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
				if (!format.test(value) && value.length !== 0) {
					errorMessage = `${t("Should have atleast one special letter")}.`;
					setError({ ...error, [name]: errorMessage });
					check = true;
				}
			}
		}

		if (!check) {
			setError({ ...error, [name]: "" });
		}
		setData({ ...data, [name]: value });
	};
	const onSubmit = () => {
		let errorArray = {};
		for (let name in data) {
			let value = data[name];
			let validationArray = validation[name];
			let errorMessage = "";
			if (name === "birth" && isNaN(new Date(value)) && value.length !== 0) {
				errorMessage = `${t("Invalid")} ${t("Birth Date")}.`;
			} else if (name === "birth") {
				errorMessage = "";
			}

			if (value !== null) {
				for (let key in validationArray) {
					if (key === "email" && validationArray[key] && !validator.isEmail(value)) {
						errorMessage = `${t("Please enter a valid email")}.`;
					} else if (key === "minLength" && value.length < validationArray[key] && value.length !== 0) {
						errorMessage = `${t("Should not be less then", { count: validationArray[key] })}.`;
					} else if (key === "maxLength" && value.length > validationArray[key]) {
						errorMessage = `${t("Should not be greater then", { count: validationArray[key] })}.`;
					} else if (key === "mandatory" && validationArray[key] && !value.length) {
						errorMessage = `${t("Should not be empty")}.`;
					} else if (key === "upperCaseReq" && value.search(/[A-Z]/) < 0 && value.length !== 0) {
						errorMessage = `${t("Should have atleast one Upper Case latter")}.`;
					} else if (key === "lowerCaseReq" && value.search(/[a-z]/) < 0 && value.length !== 0) {
						errorMessage = `${t("Should have atleast one Lower Case latter")}.`;
					} else if (key === "specialCharReq" && value.length !== 0) {
						// eslint-disable-next-line
						var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
						if (!format.test(value)) {
							errorMessage = `${t("Should have atleast one special letter")}.`;
						}
					}
				}
				errorArray[name] = errorMessage;
			}
		}
		setError(errorArray);

		let check = false;
		for (let key in errorArray) {
			if (errorArray[key]) {
				check = true;
			}
		}
		if (!check) {
			if (data.birth) {
				data.birth = formatDate(data.birth);
			}
			setLoading(true);
			updateUserDetails(user.id, data)
				.then((response) => {
					console.log("response", response);
					toast.success(response.message);
					setTimeout(() => {
						history.push("/");
					}, 1000);

					setLoading(false);
				})
				.catch((error) => {
					//console.log(error);
					if (Array.isArray(error)) {
						error.forEach((item) => {
							toast.error(item.message);
						});
					} else {
						toast.error(`${t("Something went wrong")}.`);
					}
					setLoading(false);
				});
		}
	};

	const formatDate = (value) => {
		value = new Date(value);
		const dd = String(value.getDate()).padStart(2, "0");
		const mm = String(value.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = value.getFullYear();
		const formatedDate = yyyy + "-" + mm + "-" + dd;
		return formatedDate;
	};

	const handleDate = (value) => {
		//const formatedDate = formatDate(value);
		//setCalender(false);
		setData({ ...data, birth: value });
	};
	useEffect(() => {
		if (place.hasOwnProperty("address")) {
			let curStateData = Object.assign({}, data);
			if (curStateData.hasOwnProperty("address")) {
				curStateData.address = place.address;
			}
			if (curStateData.hasOwnProperty("country")) {
				curStateData.country = place.country;
			}
			if (curStateData.hasOwnProperty("state")) {
				curStateData.state = place.state;
			}
			if (curStateData.hasOwnProperty("city")) {
				curStateData.city = place.city;
			}
			if (curStateData.hasOwnProperty("suite")) {
				curStateData.suite = place.street;
			}
			if (curStateData.hasOwnProperty("zip")) {
				curStateData.zip = place.zip;
			}
			setData(curStateData);
		}
		// eslint-disable-next-line
	}, [place]);
	const googleSelectedAddressHandler = (place) => {
		setPlace(place);
	};
	const formatChars = {
		'1': '[12]',
		'0': '[0123456789]'
	};
	return (
		<div className="page">
			<div className="container">
				<Card>
					<form role="form">
						<h1 className="main-heading">{t("Manage Your Account")}</h1>
						<div className="row">
							{/*[START:FIRSTNAME]*/}
							<div className="form-group col-md-6 theme-profile-firstName">
								<label htmlFor="first-name" className="col-sm-12 col-form-label profile-firstName-label">
									<Trans>lang_key_fname</Trans>
									{validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-firstName" 
									name="firstName" 
									className="profile-firstName form-control" 
									value={data.firstName !== null ? data.firstName : ""} 
									onChange={handleChange} 
									id="first-name" 
									placeholder={`${t("Enter ")}${t("lang_key_fname")}`}
									/>
									<span className="input-error" role="alert">{error.firstName}</span>
								</div>
							</div>
							{/*[END:FIRSTNAME]*/}

							{/*[START:LASTNAME]*/}
							<div className="form-group col-md-6 theme-profile-lastName">
								<label htmlFor="last-name" className="col-sm-12 col-form-label profile-lastName-label">
									<Trans>lang_key_lname</Trans>
									{validation["lastName"] && validation["lastName"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-lasttName" 
									name="lastName" 
									className="profile-lastName form-control" 
									value={data.lastName !== null ? data.lastName : ""} 
									onChange={handleChange} 
									id="last-name" 
									placeholder={`${t("Enter ")}${t("lang_key_lname")}`}
									/>
									<span className="input-error" role="alert">{error.lastName}</span>
								</div>
							</div>
							{/*[END:LASTNAME]*/}

							{/*[START:USERNAME]*/}
							<div className="form-group col-md-6 theme-profile-username">
								<label htmlFor="user-name" className="col-sm-12 col-form-label">
									<Trans>lang_key_username</Trans>
									{validation["username"] && validation["username"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-username" 
									name="username" 
									className="profileUserName form-control" 
									value={data.username !== null ? data.username : ""} 
									onChange={handleChange} 
									id="user-name" 
									placeholder={`${t("Enter ")}${t("lang_key_username")}`}
									disabled 
									/>
									<span className="input-error" role="alert">{error.username}</span>
								</div>
							</div>
							{/*[END:USERNAME]*/}

							{/*[START:EMAIL]*/}
							<div className="form-group col-md-6 theme-profile-email">
								<label htmlFor="user-email" className="col-sm-12 col-form-label">
									<Trans>lang_key_email</Trans>
									{validation["email"] && validation["email"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="email" 
									data-test="profile-email" 
									name="email" 
									className="profileEmail form-control" 
									value={data.email} 
									onChange={handleChange} 
									id="email" 
									placeholder={`${t("Enter ")}${t("lang_key_email")}`}
									/>
									<span className="input-error" role="alert">{error.email}</span>
								</div>
							</div>
							{/*[END:EMAIL]*/}
							{/*[START:TWITTERHANDLE]*/}
							<div className="form-group col-md-6 theme-signup-twitterHandle">
								<label htmlFor="twitterHandle" className="col-sm-12 col-form-label">
									<Trans>lang_key_twitterHandle</Trans>
									{validation["twitterHandle"] && validation["twitterHandle"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									value={data.twitterHandle} 
									data-test="register-twitterHandle" 
									name="twitterHandle" 
									onChange={handleChange} 
									className="form-control" 
									id="twitterHandle" 
									placeholder={`${t("Enter ")}${t("lang_key_twitterHandle")}`}
									/>
									<span className="input-error" role="alert">{error.twitterHandle}</span>
								</div>
							</div>
							{/*[END:TWITTERHANDLE]*/}
							{/*[START:INSTAGRAMHANDLE]*/}
							<div className="form-group col-md-6 theme-signup-instagramHandle">
								<label htmlFor="instagramHandle" className="col-sm-12 col-form-label">
									<Trans>lang_key_instaHandle</Trans>
									{validation["instagramHandle"] && validation["instagramHandle"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									value={data.instagramHandle} 
									data-test="register-instagramHandle" 
									name="instagramHandle" 
									onChange={handleChange} 
									className="form-control" 
									id="instagramHandle" 
									placeholder={`${t("Enter ")}${t("lang_key_instaHandle")}`}
									/>
									<span className="input-error" role="alert">{error.instagramHandle}</span>
								</div>
							</div>
							{/*[END:INSTAGRAMHANDLE]*/}
							{/*[START:FACEBOOKHANDLE]*/}
							<div className="form-group col-md-6 theme-signup-facebookHandle">
								<label htmlFor="facebookHandle" className="col-sm-12 col-form-label">
									<Trans>lang_key_facebookHandle</Trans>
									{validation["facebookHandle"] && validation["facebookHandle"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									value={data.facebookHandle} 
									data-test="register-facebookHandle" 
									name="facebookHandle" 
									onChange={handleChange} 
									className="form-control" 
									id="facebookHandle" 
									placeholder={`${t("Enter ")}${t("lang_key_facebookHandle")}`}
									/>
									<span className="input-error" role="alert">{error.facebookHandle}</span>
								</div>
							</div>
							{/*[END:FACEBOOKHANDLE]*/}
							{/*[START:TIKTOKHANDLE]*/}
							<div className="form-group col-md-6 theme-signup-tiktokHandle">
								<label htmlFor="tiktokHandle" className="col-sm-12 col-form-label">
									<Trans>lang_key_tiktokHandle</Trans>
									{validation["tiktokHandle"] && validation["tiktokHandle"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									value={data.tiktokHandle} 
									data-test="register-tiktokHandle" 
									name="tiktokHandle" 
									onChange={handleChange} 
									className="form-control" 
									id="tiktokHandle" 
									placeholder={`${t("Enter ")}${t("lang_key_tiktokHandle")}`}
									/>
									<span className="input-error" role="alert">{error.tiktokHandle}</span>
								</div>
							</div>
							{/*[END:TIKTOKHANDLE]*/}
							{/*[START:ADDRESS]*/}
							<div className="form-group col-md-12 theme-signup-address-finder">
								<label htmlFor="addressFinder" className="col-sm-12 col-form-label">
									<Trans>lang_key_select_address</Trans>
								</label>
								<div className="col-sm-12">
									<GooglePlaces getPlaceHandler={googleSelectedAddressHandler} placeholder={`${t("Enter ")}${t("lang_key_select_address")}`} />
								</div>
							</div>

							<div className="form-group col-md-12 theme-profile-address">
								<label htmlFor="address" className="col-sm-12 col-form-label">
									<Trans>lang_key_add_address</Trans>
									{validation["address"] && validation["address"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="register-address" 
									name="address" 
									className="profileAddress form-control" 
									value={data.address ? data.address : ""} 
									id="address" 
									placeholder={`${t("Enter ")}${t("lang_key_add_address")}`}
									/>
									<span className="input-error" role="alert">{error.address}</span>
								</div>
							</div>
							{/*[END:ADDRESS]*/}
							{/*[START:ADDRESS2]*/}
							<div className="form-group col-md-6 theme-signup-address">
								<label htmlFor="address2" className="col-sm-12 col-form-label">
									<Trans>lang_key_add_address2</Trans>
									{validation["address2"] && validation["address2"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="register-address2" 
									value={data.address2} 
									name="address2" 
									className="form-control" 
									id="address2" 
									placeholder={`${t("Enter ")}${t("lang_key_add_address2")}`}
									/>
									<span className="input-error" role="alert">{error.address2}</span>
								</div>
							</div>
							{/*[END:ADDRESS2]*/}
							{/*[START:COUNTRY]*/}
							<div className="form-group col-md-6 theme-profile-country">
								<label htmlFor="country" className="col-sm-12 col-form-label">
									<Trans>lang_key_add_country</Trans>
									{validation["country"] && validation["country"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-country" 
									name="country" 
									onChange={handleChange} 
									value={data.country ? data.country : ""} 
									className="form-control" 
									id="country" 
									placeholder={`${t("Enter ")}${t("lang_key_add_country")}`}
									/>
									<span className="input-error" role="alert">{error.country}</span>
								</div>
							</div>
							{/*[END:COUNTRY]*/}

							{/*[START:STATE]*/}
							<div className="form-group col-md-6 theme-profile-state">
								<label htmlFor="state" className="col-sm-12 col-form-label">
									{t("lang_key_add_state")}
									{validation["state"] && validation["state"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-state" 
									name="state" 
									onChange={handleChange} 
									value={data.state ? data.state : ""} 
									className="form-control" 
									id="state" 
									placeholder={`${t("Enter ")}${t("lang_key_add_state")}`}
									/>
									<span className="input-error" role="alert">{error.state}</span>
								</div>
							</div>
							{/*[END:STATE]*/}

							{/*[START:SUITE]*/}
							<div className="form-group col-md-6 theme-profile-suite">
								<label htmlFor="suite" className="col-sm-12 col-form-label">
									<Trans>lang_key_add_suite</Trans>
									{validation["suite"] && validation["suite"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-suite" 
									name="suite" 
									onChange={handleChange} 
									value={data.suite ? data.suite : ""} 
									className="form-control" 
									id="suite" 
									placeholder={`${t("Enter ")}${t("lang_key_add_suite")}`}
									/>
									<span className="input-error" role="alert">{error.suite}</span>
								</div>
							</div>
							{/*[END:SUITE]*/}

							{/*[START:CITY]*/}
							<div className="form-group col-md-6 theme-profile-city">
								<label htmlFor="city" className="col-sm-12 col-form-label">
									<Trans>City</Trans>
									{validation["city"] && validation["city"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-city" 
									name="city" 
									onChange={handleChange} 
									value={data.city ? data.city : ""} 
									className="form-control" 
									id="city" 
									placeholder={`${t("Enter ")}${t("City")}`}
									/>
									<span className="input-error" role="alert">{error.city}</span>
								</div>
							</div>
							{/*[END:CITY]*/}

							{/*[START:ZIP]*/}
							<div className="form-group col-md-6 theme-profile-zip">
								<label htmlFor="zip" className="col-sm-12 col-form-label">
									<Trans>Zip</Trans>
									{validation["zip"] && validation["zip"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-zip" 
									name="zip" 
									onChange={handleChange} 
									value={data.zip ? data.zip : ""} 
									className="form-control" 
									id="zip" 
									placeholder={`${t("Enter ")}${t("Zip")}`}
									/>
									<span className="input-error" role="alert">{error.zip}</span>
								</div>
							</div>
							{/*[END:ZIP]*/}

							{/*[START:SHIPPING]*/}
							<div className="form-group col-md-12 theme-profile-shippingInstructions">
								<label htmlFor="shippingInstructions" className="col-sm-12 col-form-label">
									<Trans>Shipping Instructions</Trans>
									{validation["shippingInstructions"] && validation["shippingInstructions"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-shippingInstructions" 
									name="shippingInstructions" 
									onChange={handleChange} 
									value={data.shippingInstructions ? data.shippingInstructions : ""} 
									className="form-control" 
									id="shippingInstructions" 
									placeholder={`${t("Enter ")}${t("Shipping Instructions")}`}
									/>
									<span className="input-error" role="alert">{error.shippingInstructions}</span>
								</div>
							</div>
							{/*[END:SHIPPING]*/}

							{/*[START:GENDER]*/}
							<div className="form-group col-md-6 theme-profile-gender">
								<label htmlFor="gender" className="col-sm-12 col-form-label theme-profile-label-gender">
									<Trans>Gender</Trans>
									{validation["gender"] && validation["gender"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<select className="form-control" 
									data-test="profile-gender" 
									name="gender" 
									onChange={handleChange} 
									value={data.gender !== null ? data.gender : ""} 
									id="gender" 
									placeholder={t("Gender")}
									>
										<option value="">
											{t("Select")} {t("Gender")}
										</option>
										<option value="m">{t("Male")}</option>
										<option value="f">{t("Female")}</option>
									</select>
									<span className="input-error" role="alert">{error.gender}</span>
								</div>
							</div>
							{/*[END:GENDER]*/}

							{/*[START:BIRTH]*/}
							<div className="form-group col-md-6 theme-profile-birth">
								<label htmlFor="birth" className="col-sm-12 col-form-label theme-profile-label-birth">
									<Trans>Birth Date</Trans>
									{validation["birth"] && validation["birth"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<InputMask
										className="form-control show-placegholder"
										mask="00/00/1000"
										maskChar={""}
										value={data.birth}
										id="birth"
										formatChars={formatChars}
										onChange={(e) => {
											handleDate(e.target.value);
										}}
										placeholder="MM/DD/YYYY"
										></InputMask>
									<span className="input-error" role="alert">{error.birth}</span>
								</div>
							</div>
							{/*[END:BIRTH]*/}

							{/*[START:HOMEPHONE]*/}
							<div className="form-group col-md-6 theme-profile-homePhone">
								<label htmlFor="homePhone" className="col-sm-12 col-form-label">
									<Trans>Home Phone</Trans>
									{validation["homePhone"] && validation["homePhone"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="number" 
									data-test="profile-homePhone" 
									name="homePhone" 
									onChange={(e) => handleChange(e)} 
									value={data.homePhone ? data.homePhone : ""} 
									className="form-control" 
									id="homePhone" 
									placeholder={`${t("Enter ")}${t("Home Phone")}`}
									/>
									<span className="input-error" role="alert">{error.homePhone}</span>
								</div>
							</div>
							{/*[END:HOMEPHONE]*/}

							{/*[START:CELLPHONE]*/}
							<div className="form-group col-md-6 theme-profile-homePhone">
								<label htmlFor="cellPhone" className="col-sm-12 col-form-label">
									<Trans>Cell Phone</Trans>
									{validation["cellPhone"] && validation["cellPhone"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="number" 
									data-test="profile-cellPhone" 
									name="cellPhone" 
									onChange={handleChange} 
									value={data.cellPhone ? data.cellPhone : ""} 
									className="form-control" 
									id="cellPhone" 
									placeholder={`${t("Enter ")}${t("Cell Phone")}`}
									/>
									<span className="input-error" role="alert">{error.cellPhone}</span>
								</div>
							</div>
							{/*[END:CELLPHONE]*/}

							{/*[START:RECEIVEEMAIL]*/}
							<div className="form-group col-md-6 theme-profile-receiveEmail">
								<label htmlFor="receiveEmail" className="col-sm-12 col-form-label theme-profile-label-receiveEmail">
									<Trans>Receive Email</Trans>
									{validation["receiveEmail"] && validation["receiveEmail"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<select className="form-control" 
									data-test="profile-email" 
									name="receiveEmail" 
									onChange={handleChange} 
									value={data.receiveEmail !== null ? data.receiveEmail : ""} 
									id="receiveEmail" 
									placeholder={t("Receive Email")}
									>
										<option value="">{t("Select")}</option>
										<option value={true}>{t("Yes")}</option>
										<option value={false}>{t("No")}</option>
									</select>
									<span className="input-error" role="alert">{error.receiveEmail}</span>
								</div>
							</div>
							{/*[END:RECEIVEEMAIL]*/}

							{/*[START:RECEIVESMS]*/}
							<div className="form-group col-md-6 theme-profile-receiveSms">
								<label htmlFor="receiveSms" className="col-sm-12 col-form-label theme-profile-label-receiveSms">
									<Trans>Receive Sms</Trans>
									{validation["receiveSms"] && validation["receiveSms"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<select className="form-control" 
									data-test="profile-receiveSms" 
									name="receiveSms" 
									onChange={handleChange} 
									value={data.receiveSms !== null ? data.receiveSms : ""} 
									id="receiveSms" 
									placeholder={t("Receive Sms")}
									>
										<option value="">{t("Select")}</option>
										<option value={true}>{t("Yes")}</option>
										<option value={false}>{t("No")}</option>
									</select>
									<span className="input-error" role="alert">{error.receiveSms}</span>
								</div>
							</div>
							{/*[END:RECEIVESMS]*/}

							{/*[START:COMPANYNAME]*/}
							<div className="form-group col-md-6 theme-profile-companyName">
								<label htmlFor="companyName" className="col-sm-12 col-form-label">
									<Trans>Company Name</Trans>
									{validation["companyName"] && validation["companyName"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									data-test="profile-companyName" 
									name="companyName" 
									onChange={handleChange} 
									value={data.companyName !== null ? data.companyName : ""} 
									className="form-control" 
									id="companyName" 
									placeholder={`${t("Enter ")}${t("Company Name")}`}
									/>
									<span className="input-error" role="alert">{error.companyName}</span>
								</div>
							</div>
							{/*[END:COMPANYNAME]*/}
							{/*[START:SSN]*/}
							<div className="form-group col-md-6 theme-signup-socialSecurityNumber">
								<label htmlFor="companyName" className="col-sm-12 col-form-label">
									<Trans>Social Security Number</Trans>
									{validation["socialSecurityNumber"] && validation["socialSecurityNumber"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<InputMask 
										className="form-control" 
										mask="999-99-9999" 
										maskChar={""} 
										value={data.socialSecurityNumber} 
										id="socialSecurityNumber" 
										name="socialSecurityNumber" 
										onChange={handleChange} 
										aria-label="Create Social Security Number"
										placeholder={`${t("Enter ")}${t("Social Security Number")}`}
										></InputMask>
									<span className="input-error" role="alert">{error.socialSecurityNumber}</span>
								</div>
							</div>
							{/*[END:SSN]*/}
							<div className="col-md-12 text-center mt-5">
								<button type="button" id="profile-submit-btn" data-test="profile-submit" onClick={onSubmit} className="btn btn-primary">
									<Trans>Save Profile</Trans>
								</button>
							</div>
						</div>
					</form>
				</Card>
			</div>
		</div>
	);
};

export default connect(null, { getUserDetails, updateUserDetails })(IsLoadingHOC(Profile, "Wait ....."));
