import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Logout from "./Logout";
import { notificationGetAction } from "../redux/actions";
import { defaultOptions } from "../config";
import NotificationCenter from "./NotificationCenter";
import { useTranslation, Trans } from "react-i18next";
import { store } from "../redux/store";

const TopRight = (props) => {
	const [showNotification, setShowNotification] = useState(false);
	const { t } = useTranslation();
	const user = useSelector(({ user }) => user);
	const dispatch = useDispatch();
	let notificationList = useSelector(({ app }) => (app.notificationCenter ? app.notificationCenter : []));
	const profileDiv = document.getElementById("href-profile");
	useEffect(() => {
		const interval = setInterval(() => {
			loadNotification();
		}, defaultOptions.loadNotificationInterval);
		return () => clearInterval(interval);
		//eslint-disable-next-line
	}, []);
	useEffect(() => {		
		if(profileDiv){
			profileDiv.addEventListener("click",(e)=>{
				if(document.getElementById("closeNotificationBtn")){
					document.getElementById("closeNotificationBtn").click();
				}
			});
			return () => {
				profileDiv.removeEventListener("click",()=>{});
			}
		}		
	},[profileDiv])
	const loadNotification = () => {
		const accessToken = store.getState("user").user.accessToken;
		if (accessToken) {
			const notifications = store.getState("app").app.notificationCenter;
			if (notifications.length > 0) {
				dispatch(notificationGetAction(notifications[0]["time"]));
			} else {
				dispatch(notificationGetAction(""));
			}
		}
	};
	return (
		<Fragment>
			{user.accessToken ? (
				<li className="nav-item">
					<Link id="href-notification" className="nav-link" to={"#"} data-toggle="tooltip" data-placement="bottom" title={t("Notification Center")} onClick={(e) => setShowNotification(!showNotification)}>
						<div className="menu-notification-icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
								<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
							</svg>
							<span className="badge">{notificationList.filter((v) => !v.isRead).length}</span>
						</div>
					</Link>
				</li>
			) : (
				""
			)}
			{showNotification ? <NotificationCenter showNotification={showNotification} parentCall={setShowNotification} /> : ""}
			
			{user.accessToken ? (
				<li className="user-pos ml-2"> 
					<div className="dropdown user-menu" id="useruldropdownmenu">
						
						{/* {t("Welcome")} {user.name ? user.name : user.username}!{" "} */}
						<button id="href-profile" className="btn dropdown-toggle user-menu-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<div className="mr-3 text-center d-none d-sm-inline-block">
								<strong>
									{user.pointBalance} <Trans>Points</Trans>
								</strong> 
								<div>
									{user.name ? user.name : user.username}
								</div>
							</div>
							<span className="d-sm-inline-block">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
									<path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"/>
								</svg>
							</span> 
						</button>
						
						<ul className="dropdown-menu dropdown-menu-right" id="userdropdownmenu">
							<div className="nav-item-div">
								<strong>
									<Trans>Points</Trans> {user.pointBalance}
								</strong>
							</div>
							{/*[START:MYALLACCESS]*/}
							<li className="nav-item">
								<a className="nav-link" id="href-all-my-access-welcome" href="/">
									{t("All My-Access")}
								</a>
							</li>
							{/*[END:MYALLACCESS]*/}

							{/*[START:POINTSHISTORY]*/}
							<li className="nav-item">
								<Link className="nav-link" id="href-point-history" to="/points-history">
									{t("Point History")}
								</Link>
							</li>
							{/*[END:POINTSHISTORY]*/}

							{/*[START:MYREWARDS]*/}
							<li className="nav-item">
								<Link className="nav-link" id="href-my-rewards" to="/my-rewards">
									{t("My Rewards")}
								</Link>
							</li>
							{/*[END:MYREWARDS]*/}

							{/*[START:PROFILE]*/}
							<li className="nav-item">
								<Link className="nav-link" id="href-profile" to="/profile">
									{t("Profile")}
								</Link>
							</li>
							{/*[END:PROFILE]*/}

							{/*[START:HELPDESK]*/}
							<li className="nav-item">
								<Link className="nav-link" id="href-help-desk" to="/help-desk">
									{t("Help Desk")}
								</Link>
							</li>
							{/*[END:HELPDESK]*/}

							{/*[START:CONNECTMOVIESANYWHERE]*/}
							<li className="nav-item">
								<a className="nav-link" id="href-connect-movie-anyhere" href="/">
									{t("Connect Movie ANYHERE")}
								</a>
							</li>
							{/*[END:CONNECTMOVIESANYWHERE]*/}
							{/*[START:LOGOUT]*/}
							<li className="nav-item">
								<Logout />
							</li>
							{/*[END:LOGOUT]*/}
						</ul>
					</div>
				</li>
				
			) : (
				<li className="nav-item">
					{/*[START:LOGIN]*/}
					<a id="href-login" className="nav-link" href="/login">
					{t("Register")}{"/"}{t("login")}{" "}
					</a>
					{/*[END:LOGIN]*/}
				</li>
			)}
			
		</Fragment>
	);
};

export default TopRight;
